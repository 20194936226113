.cms_nav_bar_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #BC1823;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  z-index: 5;
  padding: 0.8rem 20px 0.8rem 300px;
  @include media-max(1200px) {
    padding: 10px 20px;
    height: 65px;
  }
  &.active {
    padding-left: 100px;
    @include media-max(1200px) {
      padding-left: 20px;
    }
  }
  .cms_nav_bar_holder {
    @include full-width;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media-max(1200px) {
      justify-content: space-between;
    }
    .menu_bar {
      display: none;
      @include media-max(1200px) {
        display: block;
      }
    }
    .info {
      @include flex-align-center;
      position: relative;
      h3 {
        font-weight: 400;
        color: $color-white-02;
        text-transform: capitalize;
        font-size: 16px;
        @include media-max(1200px) {
          font-size: 14px;
        }
      }
      .avatar {
        @include inline-bg;
        cursor: pointer;
        margin-left: 8px;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        @include media-max(1200px) {
          width: 35px;
          height: 35px;
        }
      }
    }
    ul.dropdown_menu {
      position: absolute;
      width: 160px;
      right: 0;
      top: 120%;
      background: $color-white;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 5px;
      li {
        button {
          background: transparent;
          color: #626262;
          width: 100%;
          justify-content: flex-start;
          box-shadow: unset;
          text-transform: capitalize;
          &:hover {
            background: $color-purple;
            color: $color-white;
          }
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .notifications_select {
    position: relative;
    margin-right: 20px;
    .icon_button {
      width: auto;
      height: auto;
      .bell_icon {
        color: #6e6b7b;
        font-size: 22px;
      }
    }
    .root_icon {
      position: relative;
      cursor: pointer;
      span.length {
        width: 20px;
        height: 20px;
        background: $color-danger;
        color: $color-white;
        border-radius: 50%;
        font-size: 12px;
        @include flex-center;
        position: absolute;
        top: 3px;
        right: 3px;
        z-index: 1;
      }
    }
    .select_inner {
      position: absolute;
      z-index: 9;
      top: 100%;
      right: 0;
      background: $color-white;
      box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
      border-radius: 0.358rem;
      border: 1px solid rgba(34, 41, 47, 0.05);
      width: 28rem;
      @include media-max(900px) {
        width: 350px;
        right: -200px;
      }
      @include media-max(500px) {
        width: 300px;
        right: -210px;
      }
      .detail {
        padding: 1.22rem 1.28rem;
        border-bottom: 1px solid #ebe9f1;
        @include flex-between;
        h2 {
          color: #5e5873;
          font-size: 17px;
          font-weight: 500;
          @include media-max(500px) {
            font-size: 15px;
          }
        }
        .read_all {
          font-size: 13px;
          cursor: pointer;
          color: $color-purple;
          text-decoration: underline;
          @include hover {
            opacity: 0.8;
          }
        }
        span {
          background: rgba(115, 103, 240, 0.12);
          color: #FF00A1;
          border-radius: 10rem;
          padding: 0.3rem 0.5rem;
          font-size: 12px;
          text-align: center;
        }
      }
      .select_outer {
        .items {
          height: 300px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 7px;
          }
          &::-webkit-scrollbar-track {
            background: $color-grey-10;
          }
          &::-webkit-scrollbar-thumb {
            background: rgb(212, 212, 212);
            border-radius: 50px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: rgb(177, 177, 177);
          }
          @include media-max(500px) {
            height: 280px;
          }
          .item {
            border-bottom: 1px solid #ebe9f1;
            width: 100%;
            display: grid;
            grid-template-columns: 35px 1fr;
            align-items: center;
            grid-gap: 0 15px;
            padding: 10px 15px;
            position: relative;
            cursor: pointer;
            &.active {
              &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $color-purple;
              }
              .row {
                .title {
                  color: #000;
                }
                .message {
                  color: #000;
                }
              }
            }
            &:last-child {
              border-bottom: 0;
            }
            @include hover {
              background: $color-grey-10;
            }
            .show_more_wrapper {
              position: absolute;
              right: 30px;
              width: 30px;
              height: 30px;
              .icon {
                width: 100%;
                height: 100%;
                background: $color-white;
                border: 1px solid #ddd;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                color: grey;
              }
              .show_more {
                width: 30px;
                height: 30px;
                position: relative;
                .dropdown {
                  position: absolute;
                  right: 35px;
                  top: 0;
                  width: 200px;
                  background: $color-white;
                  border: 1px solid #ddd;
                  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                  z-index: 2;
                  border-radius: 6px;
                  overflow: hidden;
                  .row {
                    padding: 10px;
                    font-size: 14px;
                    @include hover {
                      background: $color-grey-10;
                    }
                  }
                }
              }
            }
            .icon {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background: rgba(234, 84, 85, 0.12);
              color: #ea5455;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: bold;
              svg {
                width: 70%;
                height: 70%;
              }
              &.success {
                background: rgba(40, 199, 111, 0.12);
                color: #28c76f;
              }
            }
            .row {
              .title {
                color: #6e6b7b;
                font-weight: 600;
                font-size: 15px;
                margin-bottom: 5px;
                @include media-max(500px) {
                  font-size: 13px;
                }
              }
              .message {
                color: #b9b9c3;
                font-weight: 300;
                font-size: 12px;
                height: 15px;
                overflow: hidden;
                @include media-max(500px) {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
      .empty {
        font-size: 14px;
        text-align: center;
        margin: 20px 0;
      }
      .bottom_part {
        border-top: 1px solid #ebe9f1;
        padding: 1.22rem 1.28rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .language_dropdown_wrap {
    margin-right: 20px;
    position: relative;
    .root {
      cursor: pointer;
      @include flex-align-center;
      svg {
        margin-right: 10px;
      }
      font-size: 12px;
    }
    svg {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
    .dropdown_wrap {
      position: absolute;
      top: 120%;
      right: 0;
      width: 150px;
      background: $color-white;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px;
      li {
        @include flex-align-center;
        padding: 7px 10px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 5px;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 10px;
        }
        &:hover {
          background: $color-purple;
          color: $color-white;
        }
        &.active {
          background: $color-purple;
          color: $color-white;
        }
      }
    }
  }
}
